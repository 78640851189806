import React from "react"
import Link from "./Link"
import * as EmailValidator from "email-validator"

export default function Button({ link, text, dark }) {
  return (
    <button
      className={`${
        dark === true ? "bg-primary" : "bg-sixth"
      } block  rounded-lg px-4 py-2 text-xs tracking-wider uppercase mt-4`}
    >
      {dark}
      {EmailValidator.validate(link) ? (
        <a href={`mailto:${link}`}>{text}</a>
      ) : (
        <Link to={link}>{text}</Link>
      )}
    </button>
  )
}
